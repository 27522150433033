<template>
  <a-modal
    v-model:visible="visible"
    title="编辑用户"
    @ok="handleSubmitUpdate"
    @cancel="onCancel"
    :width="720"
  >
    <a-form ref="formRef" v-bind="layout" :model="formState" :rules="rules">
      <a-form-item has-feedback label="标签名称" name="label_name">
        <a-input
          v-model:value="formState.label_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item
        has-feedback
        label="排序"
        name="sort"
        extra="排序值必须为整数"
      >
        <a-input
          class="mini-input"
          type="number"
          v-model:value="formState.sort"
        />
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="formState.remark" placeholder="" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, toRaw } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/addons/user/api";
export default defineComponent({
  name: "update-user-label",
  props: [],
  setup(props, context) {
    console.log(props);
    // 显示弹框
    const visible = ref(false);

    // 打开弹框
    const showFromModal = (item) => {
      if (item != null) {
        formState.label_id = item.label_id;
        formState.label_name = item.label_name;
        formState.remark = item.remark;
        formState.sort = item.sort.toString();
      }
      visible.value = true;
    };

    const formRef = ref();

    const formState = reactive({
      level_name: "",
      remark: "",
      sort: "0",
      status: true,
    });

    const rules = {
      level_name: [
        {
          required: true,
          min: 2,
          message: "请输入至少2个字符",
          trigger: "change",
        },
      ],
      remark: [
        {
          required: true,
          min: 2,
          message: "请输入至少2个字符",
          trigger: "change",
        },
      ],
      sort: [
        {
          required: true,
          message: "默认0",
          trigger: "change",
        },
      ],
    };

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };

    const handleSubmitUpdate = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log("values", formState, toRaw(formState));
          Api.user_label_update(formState).then(
            (res) => {
              message.success(res.message);
              visible.value = false;
              // 通知父组件刷新列表
              context.emit("handleSubmit");
            },
            (error) => {
              message.error(error);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {
      formRef.value.resetFields();
    };

    return {
      formState,
      formRef,
      rules,
      layout,
      visible,
      showFromModal,
      onCancel,
      handleSubmitUpdate,
    };
  },
});
</script>